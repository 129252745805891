const allMenus =  (environment) => {
    
    const menus = [
        {
            id: 1,
            text: 'User List',
            type: 'main',
            icon: 'mdi-account-group-outline',
            path: `/skyfi-dashboard${environment}user-list`,
            component: () => import('@/newComp/pages/UserList.vue'),
        },
        {
            id: 2,
            text: 'Business',
            type: 'inner',
            icon: 'mdi-license',
            path: `/skyfi-dashboard${environment}business/:id`,
            component: () => import('@/newComp/pages/Business.vue'),
        },
        {
            id: 3,
            text: 'All Business',
            type: 'main',
            icon: 'mdi-license',
            path: `/skyfi-dashboard${environment}business`,
            component: () => import('@/newComp/pages/Business.vue'),
        },
        {
            id: 4,
            text: 'Accounts',
            type: 'inner',
            icon: 'mdi-account-key-outline',
            path: `/skyfi-dashboard${environment}accounts/:id`,
            component: () => import('@/newComp/pages/Accounts.vue'),
        },
        {
            id: 5,
            text: 'All Accounts',
            type: 'main',
            icon: 'mdi-account-key-outline',
            path: `/skyfi-dashboard${environment}accounts`,
            component: () => import('@/newComp/pages/Accounts.vue'),
        },
        {
            id: 6,
            text: 'Cards',
            type: 'inner',
            icon: 'mdi-cards-outline',
            path: `/skyfi-dashboard${environment}cards/:id`,
            component: () => import('@/newComp/pages/Cards.vue'),
        },
        {
            id: 7,
            text: 'All Cards',
            type: 'main',
            icon: 'mdi-cards-outline',
            path: `/skyfi-dashboard${environment}cards`,
            component: () => import('@/newComp/pages/Cards.vue'),
        },
        {
            id: 8,
            text: 'Transactions',
            type: 'inner',
            icon: 'mdi-cash-multiple',
            path: `/skyfi-dashboard${environment}transactions/:id`,
            component: () => import('@/newComp/pages/Transactions.vue'),
        },
        {
            id: 9,
            text: 'All Transactions',
            type: 'main',
            icon: 'mdi-cash-multiple',
            path: `/skyfi-dashboard${environment}transactions`,
            component: () => import('@/newComp/pages/Transactions.vue'),
        },
        {
            id: 10,
            text: 'Contacts',
            type: 'inner',
            icon: 'mdi-archive-outline',
            path: `/skyfi-dashboard${environment}contacts/:id`,
            component: () => import('@/newComp/pages/Contacts.vue'),
        },
        {
            id: 11,
            text: 'All Contacts',
            type: 'main',
            icon: 'mdi-archive-outline',
            path: `/skyfi-dashboard${environment}contacts`,
            component: () => import('@/newComp/pages/Contacts.vue'),
        },
        {
            id: 12,
            text: 'Network Files',
            type: 'main',
            icon: 'mdi-web',
            path: `/skyfi-dashboard${environment}network-files`,
            component: () => import('@/newComp/pages/NetworkFiles.vue'),
        },
        {
            id: 13,
            text: 'Sameday Info',
            type: 'main',
            icon: 'mdi-information-outline',
            path: `/skyfi-dashboard${environment}sameday-info`,
            component: () => import('@/newComp/pages/SamedayData.vue'),
        },
        {
            id: 14,
            text: 'Reporting',
            type: 'main',
            icon: 'mdi-information-outline',
            path: `/skyfi-dashboard${environment}reporting`,
            component: () => import('@/newComp/pages/Reporting.vue'),
        },
        {
            id: 15,
            text: 'Staffs',
            type: 'inner',
            icon: 'mdi-cash-multiple',
            path: `/skyfi-dashboard${environment}staffs/:id`,
            component: () => import('@/newComp/pages/StaffList.vue'),
        },
    ]
        // Conditionally include the "Reporting" menu item for the staging environment
        // if (environment == '/staging/') {
          
        //     menus.push({
        //         id: 14,
        //         text: 'Reporting',
        //         type: 'main',
        //         icon: 'mdi-information-outline',
        //         path: `/skyfi-dashboard${environment}reporting`,
        //         component: () => import('@/newComp/pages/Reporting.vue'),
        //     });
    
        // }
        return menus
    
}

export default allMenus