import allMenus from './utils/menus'


const mySkyFiMenus = () => {
  var environment = '/'
  const serverType = localStorage.getItem('serverType')
  if(serverType == 'staging'){
    environment = '/staging/'
  }else if(serverType == 'livestaging'){
    environment = '/live-staging/'
  }else if(serverType == 'local'){
    environment = '/local/'
  }
  if (!localStorage.getItem('menus')) {
    if (window.location.pathname != '/admin-login') {
      window.location.pathname = '/admin-login'
    }
    return []
  } else {
    if (localStorage.getItem('menus') === 'ALL') {
      
      const adminMenusWithStaff = allMenus(environment).concat([{
        id: 13,
        text: 'Staff list',
        type: 'main',
        icon: 'mdi-account-box-outline',
        path: `/skyfi-dashboard${environment}staffs`,
        component: () => import('@/newComp/pages/StaffList.vue')
      },
      {
        id: 14,
        text: 'Create Staff',
        type: 'main',
        icon: 'mdi-account-plus-outline',
        path: `/skyfi-dashboard${environment}create-staff`,
        component: () => import('@/newComp/pages/CreateStaff.vue')
      },
      {
        id: 15,
        text: 'Create Role',
        type: 'main',
        icon: 'mdi-badge-account-outline',
        path: `/skyfi-dashboard${environment}create-role`,
        component: () => import('@/newComp/pages/CreateRole.vue')
      },
      {
        id: 16,
        text: 'Manage Role',
        type: 'main',
        icon: 'mdi-badge-account-outline',
        path: `/skyfi-dashboard${environment}roles`,
        component: () => import('@/newComp/pages/MangeRoles.vue')
      },
      {
        id: 17,
        text: 'sameday',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}sameday-info`,
        component: () => import('@/newComp/pages/SamedayData.vue')
      },
      {
        id: 18,
        text: 'Find Accounts',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}reporting/find-account`,
        component: () => import('@/newComp/pages/FindAccPage.vue')
      }, 
      {
        id: 19,
        text: 'Transactions Within Date Range',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}reporting/transaction-within`,
        component: () => import('@/newComp/pages/TransactionWithDateRange.vue')
      },   
      {
        id: 19,
        text: 'Transactions Outside Date Range',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}reporting/transactions-outside`,
        component: () => import('@/newComp/pages/TransactionsOutsideDateRange.vue')
      },   
      {
        id: 20,
        text: 'Users Within Date Range',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}reporting/users-within`,
        component: () => import('@/newComp/pages/UsersWithinDateRange.vue')
      },   
      {
        id: 21,
        text: 'Users Outside Date Range',
        type: 'main',
        icon: 'mdi-information-outline',
        path: `/skyfi-dashboard${environment}reporting/users-outside`,
        component: () => import('@/newComp/pages/UsersOutsideDateRange.vue')
      },   
      
      ])
      const mainRoutes = adminMenusWithStaff.map((item) => {
        return {
          name: item.text,
          path: item.path,
          component: item.component
        }
      })
      return mainRoutes
    } else {
      const menu_ids = JSON.parse(localStorage.getItem('menus'))
      const filteredMenu = allMenus(environment).filter(obj1 => {
        return menu_ids.some(obj2 => obj2 == obj1.id);
      });
      const mainRoutes = filteredMenu.map((item) => {
        return {
          name: item.text,
          path: item.path,
          component: item.component
        }
      })
      return mainRoutes
    }
    // return [
    //   {
    //     name: 'User List',
    //     path: '/skyfi-dashboard/user-list',
    //     component: () => import('@/newComp/pages/UserList.vue')
    //   },
    //   {
    //     name: 'Business',
    //     path: '/skyfi-dashboard/business/:id',
    //     component: () => import('@/newComp/pages/Business.vue')
    //   },
    //   {
    //     name: 'All Business',
    //     path: '/skyfi-dashboard/business',
    //     component: () => import('@/newComp/pages/Business.vue')
    //   },
    //   {
    //     name: 'Accounts',
    //     path: '/skyfi-dashboard/accounts/:id',
    //     component: () => import('@/newComp/pages/Accounts.vue')
    //   },
    //   {
    //     name: 'All Accounts',
    //     path: '/skyfi-dashboard/accounts',
    //     component: () => import('@/newComp/pages/Accounts.vue')
    //   },
    //   {
    //     name: 'Cards',
    //     path: '/skyfi-dashboard/cards/:id',
    //     component: () => import('@/newComp/pages/Cards.vue')
    //   },
    //   {
    //     name: 'All Cards',
    //     path: '/skyfi-dashboard/cards',
    //     component: () => import('@/newComp/pages/Cards.vue')
    //   },
    //   {
    //     name: 'Transactions',
    //     path: '/skyfi-dashboard/transactions/:id',
    //     component: () => import('@/newComp/pages/Transactions.vue')
    //   },
    //   {
    //     name: 'All Transactions',
    //     path: '/skyfi-dashboard/transactions',
    //     component: () => import('@/newComp/pages/Transactions.vue')
    //   },
    //   {
    //     name: 'Contacts',
    //     path: '/skyfi-dashboard/contacts/:id',
    //     component: () => import('@/newComp/pages/Contacts.vue')
    //   },
    //   {
    //     name: 'All Contacts',
    //     path: '/skyfi-dashboard/contacts',
    //     component: () => import('@/newComp/pages/Contacts.vue')
    //   },
    //   // this area is for staff
    //   {
    //     name: 'Staff list',
    //     path: '/skyfi-dashboard/staffs',
    //     component: () => import('@/newComp/pages/StaffList.vue')
    //   },
    //   {
    //     name: 'Create Staff',
    //     path: '/skyfi-dashboard/create-staff',
    //     component: () => import('@/newComp/pages/CreateStaff.vue')
    //   },
    //   {
    //     name: 'Create Role',
    //     path: '/skyfi-dashboard/create-role',
    //     component: () => import('@/newComp/pages/CreateRole.vue')
    //   },
    //   {
    //     name: 'Permission',
    //     path: '/skyfi-dashboard/permission',
    //     component: () => import('@/newComp/pages/Permission.vue')
    //   },
    //   {
    //     name: 'Manage Role',
    //     path: '/skyfi-dashboard/roles',
    //     component: () => import('@/newComp/pages/MangeRoles.vue')
    //   }
    // ]
  }
}

const routes = [
  {
    path: '/skyfi-dashboard',
    redirect: '/skyfi-dashboard',
    component: () => import('@/newComp/layout/Layout.vue'),
    children: mySkyFiMenus(),
  },
]
export default routes