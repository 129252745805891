<template>
  <div class="widgetContainer widgetContainer--center login">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" />
        <p class="title">
          Hello Admin
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form ref="user" class="wise-form">
        <el-form-item label="Email">
          <el-input placeholder="Enter Your Email" v-model="email" clearable type="email"></el-input>
        </el-form-item>
        <el-form-item label="Password">
          <el-input placeholder="Enter Your Password" v-model="password" clearable type="password"
            show-password="true"></el-input>
        </el-form-item>
        <div>
          {{ errorMessage }}
        </div>
        <div class="widgetContainer__footer--fixed border-0">
          <el-button data-testid="submit" type="primary" @click.prevent="login"
            class="el-button__brand brand width-100">
            Login
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import allMenus from '@/newComp/utils/menus.js'
// import {
//   VUE_CLIENT_ID_LOCAL,
//   VUE_CLIENT_SECRET_LOCAL,
// } from './variables'
export default {
  data() {
    const isDebug = process.env.NODE_ENV === 'development'
    return {
      email: isDebug ? 'admin@example.com' : '',
      password: isDebug ? 'password' : '',
      errorMessage: '',
      data: '',
      mainUrl: '',
      stateNewNew: 'Initial state'
    }
  },
  props: {
    currentServer: Object,
  },
  methods: {
    async login() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer?.baseUrl
        const baseurl = this.currentServer?.baseUrl
        const response = await fetch(`${baseurl}api/v1/oauth/token`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            grant_type: 'password',
            email: this.email,
            password: this.password,
            // client_id: process.env.NODE_ENV === 'development' ? VUE_CLIENT_ID_LOCAL : this.currentServer?.clientId,
            // client_secret: process.env.NODE_ENV === 'development' ? VUE_CLIENT_SECRET_LOCAL : this.currentServer?.clientSecret
            client_id: this.currentServer?.clientId,
            client_secret: this.currentServer?.clientSecret
          }),
        })
        if (!response.ok) {
          if (response.status == '400') {
            throw new Error('Invalid username or password');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json()
        // Handle successful login here
        localStorage.setItem('token', `${data.access_token}`)
        const responseNew = await fetch(`${baseurl}admin/staff_admins/me`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${data.access_token}`
          },
        })
        if (!responseNew.ok) {
          if (responseNew.status == '403') {
            throw new Error('Access denied. You must be a staff admin.');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        // alert(data.access_token)
        const dataNew = await responseNew.json()     
        if (dataNew.role === 'admin') {
          localStorage.setItem('menus', 'ALL')
          localStorage.setItem('permissions', 'ALL')
          this.email = '';
          this.password = '';
          this.$router.push(`/skyfi-dashboard/${this.currentServer.environment}business`);
          setTimeout(() => {
            location.reload()
          }, 25);
        } else {
      
          if (dataNew?.action_permissions) {
            localStorage.setItem('permissions', JSON.stringify(dataNew?.action_permissions));
          }
          if (dataNew?.menu_ids) {
            localStorage.setItem('menus', JSON.stringify(dataNew?.menu_ids));

            const filteredMenu = allMenus(this.currentServer.environment).filter(obj1 => {
              return dataNew?.menu_ids.some(obj2 => obj2 == obj1.id);
            });
            const mainRoutes = filteredMenu.map((item) => {
              return {
                name: item.text,
                path: item.path,
              }
            })
            this.email = '';
            this.password = '';
            this.$router.push(mainRoutes[0].path)
            setTimeout(() => {
              location.reload()
            }, 20);
          } else {
            this.open1('error', 'you do not have any role assigned. please make sure you have any');
          }
        }
      } catch (error) {
        if(error == 'Error: Access denied. You must be a staff admin.'){
          localStorage.setItem('permissions', 'ALL')
        }

        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Invalid username or password'.toUpperCase()) {
          this.errorMessage = 'Invalid username or password';
          setTimeout(() => {
            this.errorMessage = ''
          }, 2000);
        } else if (error.message.toUpperCase() === 'Access denied. You must be a staff admin.'.toUpperCase()) {
          localStorage.setItem('menus', 'ALL')
          this.email = '';
          this.password = '';
          this.$router.push(`/skyfi-dashboard/${this.currentServer.environment}business`);
          setTimeout(() => {
            location.reload()
          }, 20);
        } else {
          alert('something went wrong!')
        }

      }
    },
    open1(type, message) {
      const h = this.$createElement;

      this.$notify({
        title: 'Title',
        message: h('i', message),
        type: type
      });
    },
  }
}
</script>
<style lang="scss">
.widgetContainer {
  &.login {
    .widgetContainer__body {
      padding-top: 30px;
      position: relative;
    }
  }
}
</style>